<template>
  <base-form-layout
    :title="`${action} ${$tc('item.name', 1)}`"
    cols="12"
    @submit="onSubmit"
  >
    <v-form ref="form">
      <ItemRawProductEditForm :item-raw-product="itemRawProduct" />
    </v-form>
  </base-form-layout>
</template>

<script>
import ItemRawProductEditForm from './components/ItemRawProductEditForm'
import { ITEM_RAW_PRODUCT } from '@/models/item.raw.product'
import { FormMixin } from '@/mixins/form.mixin'
import { DocumentMixin } from '@/mixins/document.mixin.js'

export default {
  name: 'ItemRawProductEdit',
  components: {
    ItemRawProductEditForm
  },
  mixins: [FormMixin, DocumentMixin],
  props: {
    // itemQuotationId: {
    //   type: String,
    //   default: ''
    // },
    itemResourceId: {
      type: String,
      default: ''
    },
    itemRawProductId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      itemResource: null,
      itemRawProduct: ITEM_RAW_PRODUCT(this.itemResourceId),
      isLoading: true
    }
  },

  computed: {
    action() {
      return this.$t(this.itemRawProductId ? 'actions.edit' : 'actions.create')
    }
  },
  async created() {
    if (this.itemResourceId) {
      this.itemResource = await this.fetchItemResource()
      this.mergeAttributes()
    }
    if (this.itemRawProductId)
      this.itemRawProduct = await this.fetchItemRawProduct()
    this.isLoading = false
  },

  methods: {
    async fetchItemResource() {
      const { data } = await this.$store.dispatch(
        'itemResource/fetch',
        this.itemResourceId
      )
      return data
    },
    async fetchItemRawProduct() {
      const { data } = await this.$store.dispatch(
        'itemRawProduct/fetch',
        this.itemRawProductId
      )
      return data
    },
    mergeAttributes() {
      const {
        category,
        ncCode,
        origin,
        specie,
        type,
        incomingShipment
      } = this.itemResource.attributes.customAttributes
      const { id } = this.itemResource

      Object.assign(this.itemRawProduct.attributes.customAttributes, {
        category,
        ncCode,
        origin,
        specie,
        type,
        incomingShipment
      })
      this.itemRawProduct.attributes.customAttributes.resource = id
    },
    async onSubmit() {
      this.beforeSubmit = false
      const action = this.itemRawProductId ? 'update' : 'create'
      const { data: item } = await this.$store.dispatch(
        `itemRawProduct/${action}`,
        this.itemRawProduct
      )
      await this.createDocument(item.id, 'Item', 'Attachment')

      this.$router.go(-1)
    }
  }
}
</script>
