<template>
  <div>
    <h6 class="text-h6 mb-5">{{ $t('common.details') }}</h6>
    <v-row dense>
      <v-col cols="12" md="6">
        <base-selector
          v-model="itemRawProduct.attributes.name"
          :item-text="i => $t(`inventory.categories.${i.value}`)"
          :items="inventoryCategories"
          :label="$t('common.status')"
      /></v-col>

      <v-col v-if="!itemRawProduct.id" cols="12" md="6">
        <QuantityField filled :item="itemRawProduct"
      /></v-col>

      <v-col cols="12" md="6"
        ><base-field-input
          v-model="itemRawProduct.attributes.description"
          :label="$t('item.description')"
      /></v-col>
      <!-- <v-col cols="12" md="6">
        <ItemGroupPalletSelector :item-group="itemRawProduct"
      /></v-col> -->
      <v-col cols="12" md="6"
        ><base-selector
          v-model="itemRawProduct.attributes.facilityId"
          :items="facilities"
          :label="$t('facility.location')"
          item-text="attributes.name"
          item-value="id"
      /></v-col>
    </v-row>

    <h6 class="text-h6 mb-5">{{ $t('item.dimensions') }}</h6>
    <v-divider class="mb-8"></v-divider>
    <!-- <ItemRawProductEditFormMeasurement :item="itemRawProduct" /> -->
    <ItemEditFormMeasurement :item="itemRawProduct" :pack-option="true" />

    <h6 class="text-h6 mb-5">{{ $tc('document.attachment', 2) }}</h6>
    <v-divider class="mb-8"></v-divider>
    <base-file-input />
  </div>
</template>

<script>
// import ItemRawProductEditFormMeasurement from './ItemRawProductEditFormMeasurement'
import ItemEditFormMeasurement from '@/components/ItemEditFormMeasurement'
// import ItemGroupPalletSelector from '@/views/item-group-pallet/components/ItemGroupPalletSelector'
import QuantityField from '@/components/QuantityField'
import { INVENTORY_CATEGORIES } from '@/common/terms'
import { ORGANIZATION_ID } from '@/common/config'
export default {
  name: 'ResourceEditForm',
  components: {
    // ItemRawProductEditFormMeasurement,
    ItemEditFormMeasurement,
    QuantityField
    // ItemGroupPalletSelector
  },
  props: {
    itemRawProduct: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      inventoryCategories: INVENTORY_CATEGORIES,
      facilities: []
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.facilities = await this.fetchFacilities()
    },
    async fetchFacilities() {
      return await this.$store.dispatch('facility/filter', {
        organizationId: ORGANIZATION_ID()
      })
    }
  }
}
</script>
