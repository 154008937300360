import { ORGANIZATION_ID } from '@/common/config.js'

export const ITEM_RAW_PRODUCT = parentId => ({
  type: 'item',
  attributes: {
    name: 'log_cut_lengthwise',
    orderId: '',
    facilityId: '',
    supplierId: '',
    status: 'in_stock',
    sellingPrice: 0,
    sellingPriceCurrency: '',
    costPrice: 0,
    costPriceCurrency: '',
    tax: '',
    systemNumbers: '',
    systemNumbersUnit: '',
    organizationId: ORGANIZATION_ID(),
    parentId,
    itemGroupId: null,
    category: 'raw_product',
    description: '',
    quantity: 1,
    weight: null,
    weightUnit: '',
    dimensions: { length: null, width: null, height: null, diameter: null },
    dimensionsUnit: 'mm',
    volume: null,
    volumeUnit: 'm³',
    customAttributes: {
      packQuantity: 1
    }
  }
})
